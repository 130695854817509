'use strict';

var atrack;

/**
 * Initialize the GDDL tracker
 */
function initAnalyticsTracker() {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
}

const getActiveFilters = () => {
    const jsonData = $('.search-products__filter meta[itemprom="analytics"]').attr('content');
    if (!jsonData) return '';
    const selectedFilters = JSON.parse(jsonData);
    let selection = {};
    selectedFilters.forEach((filter) => {
        if (filter.id in selection) {
            selection[filter.id] += '|' + filter.displayValue;
        } else {
            selection[filter.id] = filter.displayValue;
        }
    });
    return selection;
};

const getSourceData = () => {
    try {
        if (sessionStorage.getItem('search_source') == null) return null;
        return JSON.parse(sessionStorage.getItem('search_source'));
    } catch (e) {
        return null;
    }
};

const deleteSourceData = () => {
    sessionStorage.removeItem('search_source');
};

/*
********* Search-query part *********
*/

const sendSearchQuery = (name, keyword, suggestedKeyword, queryAction, position) => {
    const sourceData = {
        name: name,
        keyword: keyword,
        suggested_keyword: suggestedKeyword,
        query_action: queryAction,
        filters: getActiveFilters(),
        position: position
    };
    sessionStorage.setItem('search_source', JSON.stringify(sourceData));

    const eventData = {
        event: 'search-query',
        info: {
            name: name || 'general',
            type: 'search_field',
            keyword: keyword || '',
            suggested_keyword: suggestedKeyword || '',
            query_action: queryAction || '',
            result: '',
            result_type: '',
            position: '',
            target_url: '',
            filter_selection: ''
        }
    };
    atrack.trackEvent(eventData);
};

const initSearchForm = () => {
    $('form[role="search"][name="simpleSearch"]').on('keydown', 'input', function (e) {
        if (e.key === 'Enter') {
            $('form[role="search"]').data('origin', 'enter');
        }
    }).on('submit', () => {
        const keyword = $('form[role="search"][name="simpleSearch"] input.search-field').val();
        const suggestedKeyword = $('.suggestions-wrapper .doyoumean > a').text();
        const queryAction = $('form[role="search"]').data('origin') || 'searchIcon-click';
        sendSearchQuery('general', keyword, suggestedKeyword, queryAction, '');
    });
};

const initFAQForm = () => {
    $('form[role="search"][name="faqSearch"]').on('keydown', 'input', function (e) {
        if (e.key === 'Enter') {
            $('form[role="search"]').data('origin', 'enter');
        }
    }).on('submit', () => {
        const keyword = $('form[role="search"][name="faqSearch"] input.search-field').val();
        const queryAction = $('form[role="search"]').data('origin') || 'searchIcon-click';
        sendSearchQuery('faq-search', keyword, '', queryAction, '');
    });
};

const initSuggestionBox = () => {
    $('.suggestions-wrapper').on('click', 'a', function () {
        const keyword = $('form[role="search"][name="simpleSearch"] input.search-field').val();
        const suggestedKeyword = $('.suggestions-wrapper .doyoumean > a').text();
        let queryAction = 'productSuggestion-click'; // Default to product
        let position = '';
        if ($(this).is('.doyoumean > a')) {
            queryAction = 'keywordSuggestion-click';
        } else if ($(this).is('.suggestedcategories a')) {
            queryAction = 'categorySuggestion-click';
            position = $(this).index() + 1;
        } else if ($(this).is('.suggestedcontent a')) {
            queryAction = 'contentSuggestion-click';
            position = $(this).index() + 1;
        } else if ($(this).is('a.showallproducts')) {
            queryAction = 'allResults-click';
        } else {
            position = $(this).closest('.product-wrapper').index();
        }
        sendSearchQuery('general', keyword, suggestedKeyword, queryAction, position);
    });
};

const initFirstSearchResultPage = () => {
    const pageAction = $('.page').data('action');
    const isSearchResultPage = !(new URLSearchParams($('.page').data('querystring')).get('cgid'));
    const keyword = new URLSearchParams($('.page').data('querystring')).get('q');
    if (pageAction === 'Search-Show' && isSearchResultPage && keyword && getSourceData() === null) {
        sendSearchQuery('general', keyword, '', 'url', '');
    }
};

/*
********* Search-queryresult part *********
*/

const updateSourceDataResult = (resultType, position) => {
    let sourceData = getSourceData();
    sourceData.result_type = resultType;
    sourceData.position = position;
    sessionStorage.setItem('search_source', JSON.stringify(sourceData));
};

const handleSearchPage = () => {
    const pageAction = $('.page').data('action');
    // If the cgid parameter is missing, we are on a category page, otherwise on a search result page
    const isSearchResultPage = !(new URLSearchParams($('.page').data('querystring')).get('cgid'));
    if (pageAction === 'Search-Show' && isSearchResultPage) {
        $('.search-products').on('click', '.product-tile a', function () {
            const productTile = $(this).closest('.product-tile');
            updateSourceDataResult('product-click', productTile.index('.search-products .product-tile'));
        });
        $('.search-content').on('click', '.container .cards a', function () {
            const contentTile = $(this).closest('.col-12');
            updateSourceDataResult('content-click', contentTile.index() + 1);
        });
    }
};

const handleEndResultPage = () => {
    const pageAction = $('.page').data('action');
    const isSearchResultPage = !(new URLSearchParams($('.page').data('querystring')).get('cgid'));
    const sourceData = getSourceData();
    const noResults = (pageAction === 'Search-Show' && $('.container.search .no-results').length) && $('.search-content').length === 0;
    if (sourceData && (['Page-Show', 'Product-Show'].indexOf(pageAction) !== -1 || noResults || (pageAction === 'Search-Show' && !isSearchResultPage))) {
        // We landed on an end-result page, so we have to send the queryresult event
        const eventData = {
            event: 'search-queryresult',
            info: {
                name: sourceData.name || 'general',
                type: 'search_field',
                keyword: sourceData.keyword || '',
                suggested_keyword: sourceData.suggested_keyword || '',
                query_action: sourceData.query_action || '',
                result: $('h1').text(),
                result_type: noResults ? 'no-results' : (sourceData.result_type || 'redirect'),
                position: sourceData.position || '',
                target_url: document.URL,
                filter_selection: sourceData.filters || ''
            }
        };
        atrack.trackEvent(eventData);
        deleteSourceData(); // Prevent double sending
    }
};

const init = () => {
    initAnalyticsTracker();
    initSearchForm();
    initFAQForm();
    initSuggestionBox();
    initFirstSearchResultPage();
    handleSearchPage();
    handleEndResultPage();
};

$(() => {
    init();
});
